const state = {
  question: {},
  loaded: false
}

const actions = {
  load(context, result) {
    context.commit('save',result)
  },
  clear(context) {
    context.commit('clear')
  }
}

const mutations = {
  save(state,result) {
    state.question = result
    state.loaded = true
  }
}

const getters = {
  question(state) {
    return state.question;
  }
}

export const question = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}