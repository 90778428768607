const state = {
  answer: {},
  progress: 0,
  form_id: null,
  result: {}
}

const actions = {
  submit(context, ans) {
    context.commit('save',ans)
  },
  progress(context, ans) {
    context.commit('progress',ans)
  },
  set_id(context, ans) {
    context.commit('form_id',ans)
  },
  result(context, ans) {
    context.commit('result',ans)
  },
  clear(context) {
    context.commit('clear')
  }
}

const mutations = {
  save(state,ans) {
    state.answer = Object.assign(state.answer, ans);
  },
  progress(state,ans) {
    state.progress = ans;
  },
  form_id(state,ans) {
    state.form_id = ans;
  },
  result(state,ans) {
    state.result = ans;
  },
  clear(state) {
    state.answer = {}
    state.progress = 0
    state.form_id = null
    state.result = {}
  }
}

const getters = {
  getanswer(state) {
    return state.answer;
  }
}

export const answer = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}