<template>
  <div class="home container">
    <h1 class="p-text-center">กรอกน้ำหนักและส่วนสูง</h1>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="firstname">น้ำหนัก (กิโลกรัม)</label>
        <InputText v-model="weight" @keyup="cal" class="p-inputtext-lg" type="text" />
      </div>
      <div class="p-field p-col">
        <label for="lastname">ส่วนสูง (เซนติเมตร)</label>
        <InputText v-model="height" @keyup="cal" class="p-inputtext-lg" type="text" />
      </div>
    </div>

    <div class="bmi-result" v-if="bmi>0">
      <span>Body Mass Index (BMI)</span>
      <h1>{{bmi}}</h1>
      <span v-if="bmi>25">น้ำหนักอยู่ในเกณฑ์เสี่ยง</span>
      <span v-else>น้ำหนักไม่อยู่ในเกณฑ์เสี่ยง</span>
    </div>

    <Button :disabled="bmi==0" class="c-w-100 p-button-lg p-button-raised p-button-rounded" label="ต่อไป" @click="next"></Button>
    <img src="@/assets/images/bmi.jpg" class="question-image"/>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
export default {
  name: 'BMI',
  components: {
    Button,InputText
  },
  mounted() {
    if(this.$store.state.question.loaded!=true)this.$router.push('/')
    this.$store.dispatch('answer/progress',9)
  },
  data() {
    return {
      bmi: 0
    }
  },
  methods: {
    cal() {
      if(this.weight&&this.weight>10 && this.height && this.height>50) {
        this.bmi = (this.weight/(this.height/100*this.height/100)).toFixed(1)
      } else {
        this.bmi = 0
      }
    },
    next() {
      this.$store.dispatch('answer/submit',{overweight:{
        choices: [34],
        note: this.bmi
      }})
      setTimeout(()=>{
        this.$router.push('/q/submit')
      },200)
    }
  }
}
</script>