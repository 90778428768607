<template>
  <div class="home container">
    <h1 class="p-text-center p-mb-1">ผลการประเมินความเสี่ยง</h1>
    <div v-if="result" class="result">

      <div v-if="result.infection" class="result-card">
        <h3>ความเสี่ยงที่จะติดโควิด-19</h3>
        <scale :percentage="result.infection.risk" :reasons="result.infection.reasons"/>
      </div>

      <div v-if="result.death" class="result-card">
        <h3>ความเสี่ยงที่คุณอาจป่วยหนักหรือเสียชีวิตจากโควิด-19</h3>
        <scale :percentage="result.death.risk" :reasons="result.death.reasons"/>
      </div>

      <div v-if="result.family" class="result-card">
        <h3>ความเสี่ยงต่อคนในครอบครัว</h3>
        <scale :percentage="result.family.risk" :reasons="result.family.reasons"/>
      </div>

      <p>
        <Button class="c-w-100 p-button-lg p-button-raised p-button-rounded" label="ดูความเสี่ยงของวัคซีน" @click="next"></Button>
      </p>
    </div>
  </div>
</template>

<script>
import Scale from '@/components/scale';
import Button from 'primevue/button';
export default {
  name: 'Done',
  components: {
    Scale, Button
  },
  mounted() {
    if(this.$store.state.question.loaded!=true)this.$router.push('/')
    this.$store.dispatch('answer/progress',10)
    this.result = this.$store.state.answer.result
  },
  data() {
    return {
      result: null
    }
  },
  methods: {
    next() {
      setTimeout(()=>{
        this.$router.push('/q/risk')
      },200)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
