<template>
  <AppHeader :progress="0"/>

  <router-view></router-view>
</template>

<script>
import AppHeader from '@/components/header';

export default {
  name: 'App',
  components: {
    AppHeader
  }
}
</script>

<style lang="scss">
#app {
}
</style>