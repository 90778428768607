import { createStore } from 'vuex'
import { answer } from './modules/answer';
import { question } from './modules/question';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    answer,
    question
  }
})
