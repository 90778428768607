<template>
  <div class="scale">
    <span></span>
    <strong :class="risk_color" :style="{left:left+'%'}"><i>{{title}}</i></strong>
  </div>

  <small>เหตุผล:</small>
  <ul class="reasons">
    <li v-for="(res,index) in reasons" :key="index">
      {{res}}
    </li>
  </ul>
</template>

<script>

export default {
  name: 'Choices',
  emits: ['select'],
  props: {
    percentage: Number,
    reasons: Array
  },
  methods : {
  },
  computed: {
    title() {
      let result = "";
      if(this.percentage<=25)result = "ความเสี่ยงต่ำ"
      else if(this.percentage<=50)result = "มีความเสี่ยง"
      else if(this.percentage<=75)result = "ความเสี่ยงสูง"
      else result = "ความเสี่ยงสูงมาก"
      return result
    },
    risk_color() {
      let result = "";
      if(this.percentage<=25)result = "green"
      else if(this.percentage<=50)result = "yellow"
      else if(this.percentage<=75)result = "orange"
      else result = "red"
      return "risk-"+result
    },
    left() {
      return this.percentage-13
    }
  }
}
</script>

<style scoped lang="scss">
.scale {
  position: relative;
  padding: 50px 0 0 0;
  margin: 10px 0;
  span {
    display: block;
    height: 10px;
    width: 100%;
    border-radius: 10px;
    background:linear-gradient(90deg, #7AC254 25%, #F6CE45 25%,#F6CE45 50%, #FAA61F 50%, #FAA61F 75%, #ED706A 75%);
  }

  strong {
    display:block;
    position: absolute;
    top: 45px;
    background: #fff;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: -10px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);

    &:after {
      position: absolute;
      content:"";
      display: block;
      width:14px;
      height:14px;
      top: 3px;
      left: 3px;
      border-radius: 20px;
    }
    &.risk-green:after {
      background: #7AC254;
    }
    &.risk-yellow:after {
      background: #F6CE45;
    }
    &.risk-orange:after {
      background: #FAA61F;
    }
    &.risk-red:after {
      background: #ED706A;
    }

    i {
      display: block;
      background: #fff;
      border: 1px solid #ddd;
      position: absolute;
      padding: 8px 5px 5px;
      border-radius: 5px;
      top: -50px;
      width: 140px;
      font-size: 1.1em;
      text-align: center;
      left: -60px;
      font-style: normal;
      box-shadow: 1px 1px 4px rgba(0,0,0,0.3);

      &:after, &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
      }
      &:before {
        border-color: rgba(230, 235, 233, 0);
        border-top-color: #ddd;
        border-width: 11px;
        margin-left: -11px;
      }
    }
  }
}
.reasons {
  list-style:none;
  padding: 0;
  margin: 0;
  li {
    margin: 0 0 10px;
  }
}
</style>
