import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Pre from '../views/0-Pre.vue'
import Age from '../views/1-Age.vue'
import Alone from '../views/2-Alone.vue'
import Work from '../views/3-Work.vue'
import Elder from '../views/4-Elder.vue'
import Family from '../views/5-Family.vue'
import Illness from '../views/6-Illness.vue'
import Weight from '../views/7-Weight.vue'
import BMI from '../views/8-BMI.vue'
import Submit from '../views/9-Submit.vue'
import Result from '../views/10-Result.vue'
import Risk from '../views/11-Risk.vue'
import Post from '../views/12-Post.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/q/pre',
    name: 'Pre',
    component: Pre
  },
  {
    path: '/q/age',
    name: 'Age',
    component: Age
  },
  {
    path: '/q/alone',
    name: 'Alone',
    component: Alone
  },
  {
    path: '/q/work',
    name: 'Work',
    component: Work
  },
  {
    path: '/q/elder',
    name: 'Elder',
    component: Elder
  },
  {
    path: '/q/family',
    name: 'Family',
    component: Family
  },
  {
    path: '/q/illness',
    name: 'Illness',
    component: Illness
  },
  {
    path: '/q/weight',
    name: 'Weight',
    component: Weight
  },
  {
    path: '/q/bmi',
    name: 'BMI',
    component: BMI
  },
  {
    path: '/q/submit',
    name: 'Submit',
    component: Submit
  },
  {
    path: '/q/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/q/risk',
    name: 'Risk',
    component: Risk
  },
  {
    path: '/q/post',
    name: 'Post',
    component: Post
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
