<template>
  <div class="home container">
    <h1 class="p-text-center p-mb-1">{{title}}</h1>
    <p class="p-text-center">(สามารถตอบได้มากกว่า 1 ข้อ)</p>
    <div class="multiple-choices">
      <div :class="{ selected : isSelected(choice.id) }" 
        class="multiple-choice p-ripple" v-ripple
        v-for="choice in choices" :key="choice.id" @click="toggleSelect(choice.id)">
        <span>{{choice.title}}</span>
        <i class="pi pi-check-circle"></i>
      </div>
    </div>
    <Button class="c-w-100 p-button-raised p-button-lg p-button-rounded" label="ต่อไป" @click="next"></Button>

  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: 'Family',
  components: {
    Button
  },
  mounted() {
    this.$store.dispatch('answer/progress',7)
    if(this.$store.state.question.loaded!=true){
      this.$router.push('/')
    }

    if(this.$store.state.answer.answer.underlying && this.$store.state.answer.answer.underlying.choices) {
      this.selects = this.$store.state.answer.answer.underlying.choices
    }

  },
  data() {
    return {
      selects: [],
    }
  },
  methods : {
    toggleSelect(ans) {
      if(this.selects.includes(ans)) {
        this.selects = this.selects.filter(i => i !== ans)
      } else {
        this.selects = [ ...this.selects, ans ]
      }
    },
    isSelected(id) {
      return this.selects.includes(id)
    },
    next() {
      this.$store.dispatch('answer/submit',{underlying:{choices:this.selects}})
      setTimeout(()=>{
        this.$router.push('/q/weight')
      },200)
    }
  },
  computed : {
    title() {
      return this.$store.state.question.loaded && this.$store.state.question.question.underlying.title
    },
    choices() {
      return this.$store.state.question.loaded && this.$store.state.question.question.underlying.choices
    }
  }
}
</script>