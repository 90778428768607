<template>
  <div class="home container">
    <h1 class="p-text-center p-mb-1">กำลังประเมินความเสี่ยง</h1>
    <p class="p-text-center p-mt-4"><ProgressSpinner /></p>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
import { apiService } from '@/services/api';
export default {
  name: 'Done',
  components: {
    ProgressSpinner
  },
  mounted() {
    if(this.$store.state.question.loaded!=true)this.$router.push('/')
    this.$store.dispatch('answer/progress',10)
    let answer = this.$store.state.answer.answer
    let question = []
    for(const k in answer) {
      question.push({
        handle: k,
        choices: answer[k]['choices'],
        note: answer[k]['note']
      })
    }
    let data = {questions:question}
    apiService.submit(data)
      .then((res)=>{
        if(res.form_id) {
          this.$store.dispatch('answer/set_id',res.form_id)
          this.$store.dispatch('answer/result',res.result)
          this.$router.push('/q/result')
        }
      },(res)=>{
        console.log(res)
      })
  },
  data() {
    return {
    }
  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">
.result {
  .result-card {
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    padding: 10px 15px 5px;
    border-radius: 20px;
    margin: 0 0 15px;

    h3 {
      margin: 5px 0 0 0;
      text-align: center;
    }
  }
}
.risk-list {
  list-style: disc;
}
</style>
