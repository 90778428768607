<template>
  <div class="home p-text-center">
    <div class="intro">
      <img src="@/assets/images/logo.svg" class="logo"/>
      <h2 class="p-text-center p-mt-1">ตัวช่วยตัดสินใจฉีดวัคซีน<br/><small>ภายใต้ข้อจำกัด</small></h2>
      <img src="@/assets/images/vc-0.png" class="hero-image"/>
    </div>
    <div class="form">
      <p>ข้อมูลการตอบจะถูกเก็บโดย<strong><u>ไม่ระบุตัวตนของผู้ใช้งาน</u></strong> โดยทีมงานผู้พัฒนา (ทีมงานเอกชน ซึ่งไม่หวังผลกำไร) เพื่อใช้ในการวิจัยเกี่ยวกับปัจจัยในการเลือกรับวัคซีน และงานวิจัยอื่นที่เกี่ยวข้อง</p>
      <Button icon="pi pi-play" iconPos="right" label="เริ่มต้นประเมิน" @click="choose" class="p-button-lg c-w-100"/>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import { apiService } from '@/services/api';

export default {
  name: 'Home',
  components: {
    Button
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    this.$store.dispatch('answer/clear')
    this.$store.dispatch('answer/progress',0)
    apiService.questions()
        .then((res)=>{
          if(res.questions){
            this.$store.dispatch('question/load',res.questions)
            this.loaded = true
          }
        },(res)=>{
          console.log(res)
        })
  },
  methods : {
    choose() {
      if(this.loaded){
        this.$router.push('/q/pre')
      } else {
        console.log('loading...')
        setTimeout(()=>{this.loaded},300)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.home {
  background: rgba(45,188,196,0.1);
  height: calc(100vh - 80px);
}
.intro {
  padding: 0 20px 10px;
  background: #fff;
  text-align: center;

  .logo {
    max-width: 180px;
  }
  .hero-image {
    max-width: 300px;
  }
  h2 {
    line-height: 1.2em;
    small {
      font-size: 0.7em;
    }
  }
}
.form {
  margin: 0 auto;
  max-width: 500px;
  padding: 20px 20px;
  text-align: left;
  p {
    font-size: 0.9em;
    margin-bottom: 1em;
  }
}
</style>