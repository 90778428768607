<template>
  <div class="home container">
    <h1 class="p-text-center">{{title}}</h1>

    <choices name="overweight" :choices="this.choices" @select="selected"></choices>
    <img src="@/assets/images/bmi.jpg" class="question-image"/>
  </div>
</template>

<script>
import Choices from '@/components/choices';
export default {
  name: 'Weight',
  components: {
    Choices
  },
  mounted() {
    if(this.$store.state.question.loaded!=true)this.$router.push('/')
    this.$store.dispatch('answer/progress',8)
  },
  data() {
    return {
    }
  },
  methods: {
    selected(val) {
      if(val===34){
        this.$router.push('/q/bmi')
      }else{
        this.$router.push('/q/submit')
      }
    }
  },
  computed : {
    title() {
      return this.$store.state.question.loaded && this.$store.state.question.question.overweight.title
    },
    choices() {
      return this.$store.state.question.loaded && this.$store.state.question.question.overweight.choices
    }
  }
}
</script>