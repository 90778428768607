<template>
  <div class="home container">
    <div class="result">
      <div class="result-card">
        <h3 class="p-text-center p-mb-1">ผลข้างเคียงวัคซีนที่มีขณะนี้</h3>
        <ul class="risk-list">
          <li><strong>Sinovac</strong>: แขนขาชา/อ่อนแรง จากรายงานเท่าที่มี พบจำนวน 22 ในแสนคน อาการนี้เป็นชั่วคราว ฟื้นฟูได้  (ข้อมูลของไทย 13 พ.ค. 64)</li>
          <li><strong>AstraZeneca</strong>: ลิ่มเลือดอุดตันพบจำนวน 1 ในแสนคน โอกาสเท่ากับถูกลอตเตอรี่รางวัลที่ 2 ขึ้นไป แพ้รุนแรงรวม 0.67 ในแสนคน (ข้อมูลไทย 13 พ.ค. 64)</li>
          <li>ขณะนี้วัคซีนทุกยี่ห้อ แม้จะป้องกันการติดได้ต่างกัน แต่ป้องกันการป่วยหนักหรือเสียชีวิตได้ดีเหมือนกัน 85-100%</li>
        </ul>
      </div>
      <div v-if="result && result.risks && result.risks.length > 0">
        <h1 class="p-text-center p-mb-1">วัคซีนกับคุณ</h1>
        <div class="result-card" v-for="(risk,index) in result.risks"  :key="index">
          <h3 class="p-text-center p-mb-1">{{risk.title}}</h3>
          <ul class="risk-list">
            <li v-for="(item,index) in risk.bullets" :key="index">{{item}}</li>
          </ul>
        </div>
      </div>
    </div>
    <p>
      <Button class="c-w-100 p-button-lg p-button-raised p-button-rounded" label="สรุปการตัดสินใจ" @click="next"></Button>
    </p>
  </div>
</template>

<script>
import Button from 'primevue/button';
export default {
  name: 'Done',
  components: {
    Button
  },
  mounted() {
    if(this.$store.state.question.loaded!=true)this.$router.push('/')
    this.$store.dispatch('answer/progress',10)
    console.log(this.$store.state.answer.result)
    this.result = this.$store.state.answer.result
  },
  data() {
    return {
      result: null
    }
  },
  methods: {
    next() {
      setTimeout(()=>{
        this.$router.push('/q/post')
      },200)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
