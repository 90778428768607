<template>
  <div class="home container">
    <h1 class="p-text-center p-mb-1">{{title}}</h1>
    <p class="p-text-center">(โรคเรื้อรัง เช่น เบาหวาน ความดัน มะเร็ง โรคปอด โรคตับ โรคไต โรคหัวใจ)</p>
    
    <choices name="with_elderly" next="family" :choices="this.choices"></choices>
    <img src="@/assets/images/elderly.jpg" class="question-image"/>
  </div>
</template>

<script>
import Choices from '@/components/choices';
export default {
  name: 'Alone',
  components: {
    Choices
  },
  mounted() {
    if(this.$store.state.answer.answer.alone&&this.$store.state.answer.answer.alone.choices.includes(6))this.$router.push('/q/illness')
    if(this.$store.state.question.loaded!=true)this.$router.push('/')
    this.$store.dispatch('answer/progress',5)
  },
  data() {
    return {
      
    }
  },
  computed : {
    title() {
      return this.$store.state.question.loaded && this.$store.state.question.question.with_elderly.title
    },
    choices() {
      return this.$store.state.question.loaded && this.$store.state.question.question.with_elderly.choices
    }
  }
}
</script>