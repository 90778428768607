<template>
  <div class="home container">
    <div v-if="submitted">
      <h3 class="p-text-center">ได้รับข้อมูลเรียบร้อย ขอบคุณที่ให้คุ้มกันเป็นหนึ่งตัวช่วยในการตัดสินใจเลือกวัคซีนของท่าน</h3>

      <Message :closable="false" class="disable-icon">
        <strong>ปรึกษาทีมงานแพทย์เพื่อขอข้อมูลเพิ่มเติม</strong><br/>Line ID: 
        <a href="https://line.me/ti/p/@koomgan" target="_blank">@koomgan</a>
      </Message>
      <p>
        <router-link to="/q/result" class="p-button p-component c-w-100 p-button-lg p-button-raised p-text-center p-button-rounded"><span class="p-button-label">ดูผลการประเมินความเสี่ยง</span></router-link>
      </p>
      <p class="p-text-center">
        <router-link to="/q/risk" class="p-button p-component c-w-100 p-button-lg p-button-raised  p-button-rounded"><span class="p-button-label">ดูความเสี่ยงของวัคซีน</span></router-link>
      </p>
      <p class="p-text-center">
        <router-link to="/"><i class="pi pi-replay"></i> ทำแบบทดสอบอีกครั้ง</router-link>
      </p>
    </div>
    <div v-else>
      <h1>{{title}}</h1>
      <choices name="post" :choices="choices" @select="selected"></choices>
    </div>
    <img src="@/assets/images/vaccinate.png" class="question-image"/>
  </div>
</template>

<script>
import Choices from '@/components/choices';
import { apiService } from '@/services/api';
import Message from 'primevue/message';

export default {
  name: 'Post',
  components: {
    Choices,Message
  },
  mounted() {
    if(this.$store.state.question.loaded!=true)this.$router.push('/')
    this.$store.dispatch('answer/progress',10)
    this.submitted = this.$store.state.answer.form_id==null
  },
  data() {
    return {
      submitted: false
    }
  },
  methods: {
    selected(val) {
      let data = {questions:[{handle:"post",choices:[val]}]}
      apiService.postquiz(data,this.$store.state.answer.form_id).then(()=>{
        this.submitted = true
        this.$store.dispatch('answer/set_id',null)
      },()=>{})
    }
  },
  computed : {
    title() {
      return this.$store.state.question.loaded && this.$store.state.question.question.post.title
    },
    choices() {
      return this.$store.state.question.loaded && this.$store.state.question.question.post.choices
    }
  }
}
</script>

<style lang="scss">
.disable-icon {
  .p-message-icon {
    display: none;
  }
}
</style>