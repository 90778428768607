<template>
  <div class="home container">
    <h1 class="p-text-center">{{title}}</h1>

    <choices name="lifestyle_family" next="illness" :choices="this.choices"></choices>
    <img src="@/assets/images/family.jpg" class="question-image"/>
  </div>
</template>

<script>
import Choices from '@/components/choices';
export default {
  name: 'Family',
  components: {
    Choices
  },
  mounted() {
    if(this.$store.state.answer.answer.alone&&this.$store.state.answer.answer.alone.choices.includes(6))this.$router.push('/q/illness')    
    if(this.$store.state.question.loaded!=true)this.$router.push('/')
    this.$store.dispatch('answer/progress',6)
  },
  data() {
    return {
    }
  },
  computed : {
    title() {
      return this.$store.state.question.loaded && this.$store.state.question.question.lifestyle_family.title
    },
    choices() {
      return this.$store.state.question.loaded && this.$store.state.question.question.lifestyle_family.choices
    }
  }
}
</script>