import axios from 'axios'

const REST_ENDPOINT = 'https://api.koomgan.com/api/'

export const apiService = {
  questions,
  submit,
  postquiz
};

function questions () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}questions`,
      method: 'GET',
      headers: {'accept': 'application/json'}
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}

function submit (data) {
  return new Promise((resolve, reject) => {
    data = { 
      ...data
    }
    axios({
      url: `${REST_ENDPOINT}form`,
      method: 'POST',
      data: data,
      headers: {'accept': 'application/json'}
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}

function postquiz (data,id) {
  return new Promise((resolve, reject) => {
    data = { 
      ...data
    }
    axios({
      url: `${REST_ENDPOINT}form/${id}`,
      method: 'POST',
      data: data,
      headers: {'accept': 'application/json'}
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}