<template>
  <div class="choices">
    <div class="choice p-ripple" :class="{active:isActive(choice.id)}" ontouchstart="" v-ripple v-for="choice in choices" :key="choice.id" @click="choose(choice.id)">
      <strong v-if="choice.title">{{choice.title}}</strong>
      <span v-if="choice.subtitle">{{choice.subtitle}}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Choices',
  emits: ['select'],
  props: {
    choices: Array,
    name: String,
    next: String
  },
  mounted() {

  },
  methods : {
    choose(ans) {
      this.$store.dispatch('answer/submit',{[this.name]:{choices:[ans]}})
      setTimeout(()=>{
        if(this.next)this.$router.push('/q/'+this.next)
        this.$emit('select',ans);
      },200)
    },
    isActive(id) {
      let result = false
      if(this.$store.state.answer.answer[this.name] && this.$store.state.answer.answer[this.name].choices.includes(id))result = true
      return result
    }
  }
}
</script>

<style scoped lang="scss">

</style>
