<template>
  <div class="home container">
    <h1 class="p-text-center">{{title}}</h1>

    <choices name="pre" next="age" :choices="this.choices"></choices>
    <img src="@/assets/images/vc-1.png" class="question-image"/>
  </div>
</template>

<script>
import Choices from '@/components/choices';

export default {
  name: 'Pre',
  components: {
    Choices
  },
  mounted() {
    if(this.$store.state.question.loaded!=true)this.$router.push('/')
    this.$store.dispatch('answer/progress',1)
  },
  data() {
    return {
    }
  },
  computed : {
    title() {
      return this.$store.state.question.loaded && this.$store.state.question.question.pre.title
    },
    choices() {
      return this.$store.state.question.loaded && this.$store.state.question.question.pre.choices
    }
  }
}
</script>