<template>
  <header class="app-header">
    <div class="p-d-flex p-jc-between p-ai-center">
      <i v-if="can_go_back" class="pi pi-angle-left" @click="back" style="fontSize: 1.4rem;color:#8C8C8C;opacity:0.6" />
      <router-link to="/"><img src="@/assets/images/logo.svg" width="90" class="p-mt-1"/></router-link>
      <i class="pi pi-bars" @click="sidebarVisible = true" style="fontSize: 1.4rem;color:#8C8C8C;opacity:0.6" />
    </div>
  </header>
  <ProgressBar :value="progress_value" :showValue="false"/>
  <Sidebar v-model:visible="sidebarVisible">
    <img src="@/assets/images/logo.svg" width="90"/>
    <ul class="sidebar-nav">
      <li><router-link @click="sidebarVisible=false" to="/">ทำแบบประเมิน</router-link></li>
      <li><router-link @click="sidebarVisible=false" to="/about">เกี่ยวกับเรา</router-link></li>
    </ul>
  </Sidebar>
</template>

<script>
import ProgressBar from 'primevue/progressbar';
import Sidebar from 'primevue/sidebar';

export default {
  name: 'AppHeader',
  components: {
    ProgressBar,Sidebar
  },
  data() {
    return {
      sidebarVisible: false
    }
  },
  props: {
    percentage: Number
  },
  computed: {
    progress_value() {
      const all_step = 10;
      return Math.round((this.$store.state.answer.progress/all_step) * 100)
    },
    can_go_back(){
      let result = true
      if( ['Home','Submit','Result'].includes(this.$route.name) )result = false
      return result
    }
  },
  methods: {
    back() {
      if( ['Illness'].includes(this.$route.name) && this.$store.state.answer.answer.alone&&this.$store.state.answer.answer.alone.choices.includes(6)) {
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.app-header {
  height: 55px;
  padding: 9px 15px 5px;
}
.logo {
  height: 100%;
}
.sidebar-nav {
  list-style:none;
  padding:0;
  margin: 20px 0 0 ;
  font-size: 1.3em;
  border-bottom: 1px solid #ddd;
  color: $color-trinary;

  li {
    line-height: 3em;
    border-top: 1px solid #ddd;
  }
  a {
    display: block;
  }
}
</style>
